import React from 'react';
import './Groups.css';
import PropTypes from 'prop-types';
import { List, Tabs, Flex, Avatar } from 'antd';
import { TOURNAMENT_STATES } from '../constants';
import {getRoundRobinOneGroupAParticipants,
	getRoundRobinOneGroupBParticipants,
	getRoundRobinTwoGroupAParticipants,
	getRoundRobinTwoGroupBParticipants,
	getRandomParticipantAvatar } from '../helpers/tournament';

const isPostRoundRobinOne = (tournamentState) => 
	[TOURNAMENT_STATES.completed, TOURNAMENT_STATES.roundRobinTwo].includes(tournamentState);

const buildGroupParticipants = (tournamentDetails, getParticipants) => 
	getParticipants(tournamentDetails)
		.filter(p => tournamentDetails.players.map(pl => pl.name).includes(p))
		.map(p => tournamentDetails.players.find(player => player.name === p))
		.sort()
		.map((p, idx) => {
			return {
				...p,
				key: idx + 1
			};
		});

const renderGroupParticipant = (player) => (
	<List.Item>
		<List.Item.Meta
			avatar={<Avatar src={player.image || getRandomParticipantAvatar(player.key)} />}
			title={player.name}
		/>
	</List.Item>
);

const Groups = ({ tournamentState, tournamentDetails }) => (			
	<Tabs
		centered='true'
		className='groups-wrapper'
		defaultActiveKey={isPostRoundRobinOne(tournamentState) ? 'round_robin_two' : 'round_robin_one'}
		items={[
			{
				key: 'round_robin_one',
				label: <span>Round 1</span>,
				children: (
					<Flex gap="large">
						<List className='groups-list'
							header={<span>Group A</span>}
							dataSource={buildGroupParticipants(tournamentDetails, getRoundRobinOneGroupAParticipants)}
							renderItem={renderGroupParticipant}
						/>
						<List className='groups-list'
							header={<span>Group B</span>}
							dataSource={buildGroupParticipants(tournamentDetails, getRoundRobinOneGroupBParticipants)}
							renderItem={renderGroupParticipant}
						/>
					</Flex>
				),
			},
			{
				key: 'round_robin_two',
				label: <span style={isPostRoundRobinOne(tournamentState) ? {
				} : {
					opacity: '30%' 
				}}>Round 2</span>,
				children: (
					<Flex gap="large">
						<List className='groups-list'
							header={<span>Top Four</span>}
							dataSource={buildGroupParticipants(tournamentDetails, getRoundRobinTwoGroupAParticipants)}
							renderItem={renderGroupParticipant}
						/>
						<List className='groups-list'
							header={<span>Bottom Four</span>}
							dataSource={buildGroupParticipants(tournamentDetails, getRoundRobinTwoGroupBParticipants)}
							renderItem={renderGroupParticipant}
						/>
					</Flex>
				),
				disabled: !isPostRoundRobinOne(tournamentState),
			},
		]}
	/>
);

Groups.propTypes = {
	tournamentState: PropTypes.string,
	tournamentDetails: PropTypes.object
};

export default Groups;