import { MATCHES_DEFAULT_TAGS } from '../constants';

export const getAllTags = (matches) => {
	return matches.length === 0 
		? new Set() 
		: new Set(Object.entries(MATCHES_DEFAULT_TAGS).map(([, tag]) => tag.label))
			.union(getAllCustomTags(matches));
};

export const getMatchTypeTag = (match) => Object.entries(MATCHES_DEFAULT_TAGS)
	.filter(([, tag]) => tag.type === match.type)
	.map(([, tag]) => tag)
	.find(() => true);
export const getMatchTagLabels = (match) => [getMatchTypeTag(match)]
	.filter(t => !!t)
	.map(tag => tag.label)
	.concat(getCustomTags(match));

// Custom tags for a specific match
export const getCustomTags = (match) => (match.customTags || '').split(',').filter(v => !!v);

// Custom tags for a group of matches
export const getAllCustomTags = (matches) => new Set(matches.flatMap(getCustomTags));