import React, { useEffect, useState } from 'react';
import './TournamentDetails.css';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { getTournamentState } from '../helpers/tournament';
import { TOURNAMENT_STATES } from '../constants';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Standings from './Standings';
import Groups from './Groups';
import Schedule from './Schedule';

dayjs.extend(relativeTime);

const TournamentDetails = ({ tournamentDetails = new Object() }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [tournamentState, setTournamentState] = useState(getTournamentState(tournamentDetails));
	const { players, standings, scheduleAndResults } = tournamentDetails;

	useEffect(() => {
		if (
			players && standings && scheduleAndResults
        && players.length > 0
        && standings.length > 0
        && scheduleAndResults.length > 0
		) {
			setIsLoading(false);
		}

		setTournamentState(getTournamentState(tournamentDetails));
	}, [tournamentDetails]);

	return (
		<div className='tournament-details-wrapper'>
			<div className='list-wrapper'>
				{isLoading ? (
					<div className='w-100 text-center'>
						<Spin fullscreen />
					</div>
				) : (
					<>
						{
							// Tweak this when testing
							tournamentState === TOURNAMENT_STATES.notStarted
								? (<></>) : (
									<>
										<h2 className='card-header'>
										🏆 Standings
										</h2>
										<Standings standings={standings} players={players} />
									</>
								)
						}
						<h2 className='card-header'>
							Groups
						</h2>
						<Groups tournamentState={tournamentState} tournamentDetails={tournamentDetails} />
						<h2 className='card-header'>
							Schedule
						</h2>
						<Schedule scheduleAndResults={scheduleAndResults} />
					</>
				)}
			</div>
		</div>
	);
};

TournamentDetails.propTypes = {
	tournamentDetails: PropTypes.object
};

export default React.memo(TournamentDetails);