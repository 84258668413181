import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import './Main.css';
import Home from '../pages/Home';
import Schedule from '../pages/Results';
import Gallery from '../pages/Gallery';
import Matches from '../pages/Matches';
import { getGeneralDetails, getPickletesGallery, getTournamentDetails } from '../api';
import {DEFAULT_SEASON,
	TOURNAMENT_DETAILS_REFRESH_RATE_MILLIS,} from '../constants';

const Main = () => {
	const [players, setPlayers] = useState([]);
	const [matches, setMatches] = useState([]);
	const [standings, setStandings] = useState([]);
	const [scheduleAndResults, setScheduleAndResults] = useState([]);
	const [galleryImages, setGalleryImages] = useState(new Object());

	useEffect(() => {
		getGeneralDetails(DEFAULT_SEASON).then((res) => {
			setPlayers((res.players || []).sort(() => 0.5 - Math.random()));
			setMatches(res.matches);
		});

		getPickletesGallery().then(setGalleryImages);
		
		const fetchTournamentDetails = () => {
			getTournamentDetails(DEFAULT_SEASON).then((res) => {
				setStandings(res.standings);
				setScheduleAndResults(res.scheduleAndResults);
			});
		};

		fetchTournamentDetails();
		setInterval(() => {
			fetchTournamentDetails();
		}, TOURNAMENT_DETAILS_REFRESH_RATE_MILLIS);
	}, []);

	const tournamentDetails = {
		players: players,
		standings: standings,
		scheduleAndResults: scheduleAndResults,
	};

	return (
		<Routes>
			<Route path="/" element={<Home tournamentDetails={tournamentDetails} allMatches={matches} />} />
			<Route path="results" element={<Schedule tournamentDetails={tournamentDetails} />} />
			<Route path="gallery" element={<Gallery images={galleryImages} />} />
			<Route path="matches" element={<Matches allMatches={matches} />} />
			<Route path="*" element={<Navigate to="/" replace />} />
		</Routes>
	);
};

Main.propTypes = {
};

export default React.memo(Main);
