import React from 'react';
import PropTypes from 'prop-types';
import './MatchTag.css';
import { Tag } from 'antd';

const MatchTag = ({ color, label }) => (
	<Tag className='match-tag' color={color}>{label}</Tag>
);

MatchTag.propTypes = {
	color: PropTypes.string,
	label: PropTypes.string
};

export default React.memo(MatchTag);
