import React from 'react';
import PropTypes from 'prop-types';
import './NavigationBar.css';
import { Tabs } from 'antd';
import StickyBox from 'react-sticky-box';
import Logo from '../assets/logo.png';
import { Link } from 'react-router-dom';

const renderTabBar = (props, DefaultTabBar) => (
	<div>
		<a id='nav-logo' href='/'>
			<img src ={Logo} alt =''></img>
		</a>
		<DefaultTabBar
			{...props}
		/>
	</div>
);

const NavigationBar = ({ activeKey }) => (
	<StickyBox
		offsetTop={24}
		style={{
			zIndex: 3,
		}}
	>	
		<Tabs
			id={'navigation-bar'}
			defaultActiveKey={activeKey}
			activeKey={activeKey}
			renderTabBar={renderTabBar}
			animated={{ 
				inkBar: false, 
				tabPane: true 
			}}
			items={[
				{
					key: 'home',
					label: <Link to='../' >Home</Link>,
				},
				{
					key: 'results',
					label: <Link to='../results'>Results</Link>,
				},
				{
					key: 'gallery',
					label: <Link to='../gallery'>Gallery</Link>,
				},
				{
					key: 'matches',
					label: <Link to='../matches'>Matches</Link>,
				},
			]}
		/>
	</StickyBox>
);

NavigationBar.propTypes = {
	activeKey: PropTypes.string
};

export default React.memo(NavigationBar);