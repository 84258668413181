import React from 'react';
import PropTypes from 'prop-types';
import './Match.css';
import ResponsiveEmbed from 'react-responsive-embed';
import { Card, Badge } from 'antd';
import { CalendarOutlined, VideoCameraOutlined } from '@ant-design/icons';
import MatchTag from './MatchTag';
import { getMatchTypeTag, getCustomTags } from '../helpers/matches';
import { MATCHES_DEFAULT_MATCH_TAG_COLOR } from '../constants';

const toMinTwoDigits = (number) => String(number).padStart(2, '0');

const renderNewBadge = (match, inner) => JSON.parse((match.isNew || '').toLowerCase())
	? (<Badge.Ribbon color='volcano' text='New'>{inner}</Badge.Ribbon>)
	: inner;

const renderTags = (match, colorsForTag) => {
	const typeMatchTag = [getMatchTypeTag(match)]
		.filter(t => !!t)
		.map(tag => <MatchTag key={tag.type} color={tag.color} label={tag.label} />);
	const customMatchTags = getCustomTags(match)
		.map(tag => <MatchTag key={tag} color={colorsForTag[tag] || MATCHES_DEFAULT_MATCH_TAG_COLOR } label={tag} />);
	const tags = typeMatchTag.concat(customMatchTags);

	return tags.length === 0 
		? <></> 
		: <div className='match-tags'>
			{tags}
		</div>;
};

const Match = ({ match, colorsForTag = new Object() }) => {
	return (
		<div className='card-wrapper'>
			{
				renderNewBadge(
					match, (
						<Card>
							<div className='match-results-wrapper'>
								<div className='team-wrapper left'>
									<p className='team-players left'>{match.teams[0].players[0]} / {match.teams[0].players[1]}</p>
									<p className ='match-score'>{toMinTwoDigits(match.teams[0].points)}</p>
								</div>
								<div className='vs-wrapper'>
									<p>vs</p>
								</div>
								<div className='team-wrapper right'>
									<p className='match-score'>{toMinTwoDigits(match.teams[1].points)}</p>
									<p className='team-players right'>{match.teams[1].players[0]} / {match.teams[1].players[1]}</p>
								</div>
							</div>
							<ResponsiveEmbed src={match.youtube} allowFullScreen ratio='16:9'/>
							<div className='match-video-info'>
								<div className='match-video-date'>
									<p><CalendarOutlined  /> {new Date(match.date).toLocaleDateString('en-US', {
										day: 'numeric', month: 'short', year: 'numeric' 
									})}</p>
								</div>
								<p className='mobile-hide'>|</p>
								<div className='match-video-link'>
									<p><VideoCameraOutlined /> <a target='_blank' href={match.swingvision} rel="noreferrer">Swingvision</a></p>
								</div>
								{renderTags(match, colorsForTag)}
							</div>
						</Card>
					)
				)
			}
		</div>
	);
};

Match.propTypes = {
	match: PropTypes.object,
	colorsForTag: PropTypes.object
};

export default React.memo(Match);
