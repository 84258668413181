const IMAGE_EXTENSIONS = [
	'jpg', 'jpeg', 'png', 'heic', 'gif', 'svg', 'tiff'
];
const VIDEO_EXTENSIONS = [
	'mp4', 'mov', 'webm', 'flv', 'avi', 'mkv'
];

export const FILE_TYPE_UNKNOWN = 'unknown';
export const FILE_TYPE_IMAGE = 'image';
export const FILE_TYPE_VIDEO = 'video';
export const FILE_TYPES = {
	unknown: FILE_TYPE_UNKNOWN,
	image: FILE_TYPE_IMAGE,
	video: FILE_TYPE_VIDEO
};

export const getFileType = (urlOrFileName) => {
	const extension = urlOrFileName.split('.').pop() || '';
	if (IMAGE_EXTENSIONS.includes(extension)) {
		return FILE_TYPE_IMAGE;
	} else if (VIDEO_EXTENSIONS.includes(extension)) {
		return FILE_TYPE_VIDEO;
	}

	return FILE_TYPE_UNKNOWN;
};
    