import React from 'react';
import './Players.css';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { List, Descriptions, Popover, Image } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faClock, 
	faHand, 
	faTableTennisPaddleBall, 
	faPersonRunning,
	faStar, 
	faCircleQuestion,
	faChartColumn,
	faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { getRandomParticipantAvatar } from '../helpers/tournament';
import { getFileType, FILE_TYPES } from '../helpers/media';

const MISSING_PLAYER_INFO_PLACEHOLDER = '-';

const renderPopoverLink = (text, media) => {
	let content = text;
	const fileType = getFileType(media);

	switch (fileType) {
	case FILE_TYPES.unknown:
		return content;
	case FILE_TYPES.image:
		content = (
			<Image 
				src={media} 
				placeholder={false} 
				preview={false} 
				width={300}
			/>
		);
		break;
	case FILE_TYPES.video:
		content = (
			<video width="300" autoPlay="autoplay" controls>
				<source src={media} type="video/mp4"/>
			</video>
		);
	}

	return (
		<Popover 
			placement="top" 
			content={content}
			trigger="click" 
			overlayInnerStyle={{ 
				padding: '6px', 
				backgroundColor: 'white' 
			}}
		>
			<span style={{
				cursor: 'pointer' 
			}}><u>{text} </u></span>
			<FontAwesomeIcon icon={faCircleQuestion} className="fa-xs" />
		</Popover>
	);
};

const renderStatistics = (player, allMatches) => {
	const matches = allMatches
		.filter(m => m.teams.flatMap(t => t.players).includes(player.name));
		
	if (matches.length === 0) {
		return '-';
	}

	// A match is considered won if a player belongs on the team
	// with more points than the other team(s) which does not include the player
	//
	// If a player on neither team, they are not considered to have won the match.
	const wonMatch = (player, match) => 
		parseInt(match.teams.find(t => t.players.includes(player.name))?.points || '0') >
		parseInt(match.teams.find(t => !t.players.includes(player.name))?.points || '0');
	
	const matchesWon = matches.filter(m => wonMatch(player, m)).length;
	const matchesLost = matches.length - matchesWon;

	return (
		<>
			<span>{`${matchesWon}-${matchesLost}`}</span>
			<Link to={`/matches?team_a=${player.name}&sort=desc`}>
				<FontAwesomeIcon icon={faUpRightFromSquare} className="fa-xs player-statistics-link" />
			</Link>
		</>
	);
};

const renderPlayer = (player, allMatches) => {
	const calculateExperienceMonths = (startDate) => dayjs(startDate).toNow(true);
	const descriptionItems = [
		{
			key: 'experience',
			label: <FontAwesomeIcon icon={faClock} className="fa-xl" />,
			children: `${calculateExperienceMonths(player.startDate)}`
		},
		{
			key: 'handedness',
			label: <FontAwesomeIcon icon={faHand} className="fa-xl" />,
			children: player.handedness
		},
		{
			key: 'paddle',
			label: <FontAwesomeIcon icon={faTableTennisPaddleBall} className="fa-xl" />,
			children: player.paddle
		},
		{
			key: 'background',
			label: <FontAwesomeIcon icon={faPersonRunning} className="fa-xl" />,
			children: player.background
		},
		{
			key: 'specialty',
			label: <FontAwesomeIcon icon={faStar} className="fa-xl" />,
			children: renderPopoverLink(player.specialty, player.specialtyMedia)
		},
		{
			key: 'statistics',
			label: <FontAwesomeIcon icon={faChartColumn} className="fa-xl" />,
			children: renderStatistics(player, allMatches)
		}
	].map(i => {
		return {
			...i,
			children: i.children || MISSING_PLAYER_INFO_PLACEHOLDER
		};
	});

	return (
		<List.Item>
			<p className='player-number'>#{player.key}</p>
			<div style={{
				display:'flex', width:'100%', alignItem:'center', marginTop: 16, marginBottom: 16
			}}>
				<img className ='player-avatar' src={player.image || getRandomParticipantAvatar(player.key)}/>
				<p className='player-name'>{renderPopoverLink(player.name, player.playerMedia)}</p>
			</div>
			<Descriptions 
				className='player-description' 
				items={descriptionItems} 
				column={1} 			
				colon={false}		
			/>
		</List.Item>
	);
};

const Players = ({ players = [], allMatches = [] }) => (
	<>
		<h2 className='card-header'>
		Meet the players...
		</h2>
		<List
			className='players-grid'
			dataSource={players.map((p, idx) => {
				return {
					...p,
					key: idx + 1
				};
			})}
			renderItem={(item) => renderPlayer(item, allMatches)}
		/>
	</>
);

Players.propTypes = {
	players: PropTypes.array,
	allMatches: PropTypes.array
};

export default Players;