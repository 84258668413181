import React from 'react';
import './Standings.css';
import Crown from '../assets/crown.svg';
import PropTypes from 'prop-types';
import { List } from 'antd';
import { getRandomParticipantAvatar } from '../helpers/tournament';

const renderStanding = (item) => {
	switch (item.position) {
	case 1:
		return (
			<List.Item className='podium gold-player'>
				<div className='player-info'>
					<img src={Crown}/>
					<img className='player-avatar' src={item.image} />
					<p>1</p>
					<div className='name-wrapper'> 
						{item.name}
					</div>
				</div>
				<div className='block'></div>
			</List.Item>
		);
	case 2:
		return (
			<List.Item className='podium silver-player'>
				<div className='player-info'>
					<img className='player-avatar' src={item.image} />
					<p>2</p>
					<div className='name-wrapper'> 
						{item.name}
					</div>
				</div>
				<div className='block'></div>
			</List.Item>
		);
	case 3:
		return (
			<List.Item className='podium bronze-player'>
				<div className='player-info'>
					<img className='player-avatar' src={item.image} />
					<p>3</p>
					<div className='name-wrapper'> 
						{item.name}
					</div>
				</div>
				<div className='block'></div>
			</List.Item>
		);
	default:
		return <></>;
	}
};

const Standings = ({ standings, players }) => (
	<List
		className='standings-wrapper'
		bordered
		dataSource={
			standings.map(s => {
				const player = players.map((p, idx) => {
					return {
						...p,
						key: idx + 1
					};
				}).find(p => p.name === s.name);

				return {
					...s,
					image: player.image || getRandomParticipantAvatar(player.key)
				};
			})
		}
		renderItem={renderStanding}
	/>
);

Standings.propTypes = {
	standings: PropTypes.array,
	players: PropTypes.array
};

export default Standings;