import React from 'react';
import './Results.css'; 
import PropTypes from 'prop-types';
import TournamentDetails from '../components/TournamentDetails';
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';

const Schedule = ({ tournamentDetails = new Object() }) => {
	return (
		<section className ='body-wrapper schedule-body-wrapper'>
			<NavigationBar activeKey='results' />
			<div id="schedule-container" className='container'>
				<TournamentDetails tournamentDetails={tournamentDetails} />
				<Footer />
			</div>
		</section>
	);
};

Schedule.propTypes = {
	tournamentDetails: PropTypes.object
};

export default React.memo(Schedule);
