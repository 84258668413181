import React from 'react';

const SAMPLE_GALLERY_MAIN_IMAGE_FILES = require.context('./assets/gallery/main/', true);
export const SAMPLE_GALLERY_MAIN_IMAGES = SAMPLE_GALLERY_MAIN_IMAGE_FILES.keys().map(image => SAMPLE_GALLERY_MAIN_IMAGE_FILES(image));

const SAMPLE_GALLERY_LITTLE_PICKLETES_CUP_SEASON_1_IMAGE_FILES = require.context('./assets/gallery/little_pickletes_cup_season_1/', true);
export const SAMPLE_GALLERY_LITTLE_PICKLETES_CUP_SEASON_1_IMAGES = SAMPLE_GALLERY_LITTLE_PICKLETES_CUP_SEASON_1_IMAGE_FILES.keys().map(image => SAMPLE_GALLERY_LITTLE_PICKLETES_CUP_SEASON_1_IMAGE_FILES(image));

const RANDOM_PARTICIPANT_IMAGE_FILES = require.context('./assets/placeholder_participant_avatars/', true);
export const RANDOM_PARTICIPANT_IMAGES = RANDOM_PARTICIPANT_IMAGE_FILES.keys().map(image => RANDOM_PARTICIPANT_IMAGE_FILES(image));

export const DEFAULT_SEASON = '1';
export const SEASON_1_DATE = new Date(2024, 11, 20); // Month is indexed at 0, so 11 for December
export const SEASON_1_DATE_YYYY_MM_DD = `${SEASON_1_DATE.getFullYear()}-${(SEASON_1_DATE.getMonth()+1).toLocaleString('en-US', {
	minimumIntegerDigits: 2, useGrouping:false
})}-${SEASON_1_DATE.getDate().toLocaleString('en-US', {
	minimumIntegerDigits: 2, useGrouping:false
})}`;
export const SEASON_1_START_DATE_TIME =
	SEASON_1_DATE.getTime() + 18 * 60 * 60 * 1000; // 18 hours after local time 00:00
export const SEASON_1_PROMO_VIDEO = 'https://www.youtube.com/embed/VtkmtY-7xyo';
export const TOURNAMENT_DETAILS_REFRESH_RATE_MILLIS = 5000;
export const LAMBDA_FUNCTION_URL =
  'https://f5tknvbs2jrkhvcdprxnagffam0xyipd.lambda-url.us-east-1.on.aws';
export const S3_MEDIA_BUCKET_CDN_URL = 
  'https://dpbyjtkhyxkw4.cloudfront.net';

export const TOURNAMENT_WINNING_POINT = 21; // Games are played to this number of points
export const TOURNAMENT_STATE_INVALID = 'INVALID';
export const TOURNAMENT_STATE_NOT_STARTED = 'NOT_STARTED';
export const TOURNAMENT_STATE_ROUND_ROBIN_ONE = 'ROUND_ROBIN_ONE';
export const TOURNAMENT_STATE_ROUND_ROBIN_TWO = 'ROUND_ROBIN_TWO';
export const TOURNAMENT_STATE_COMPLETED = 'COMPLETED';
export const TOURNAMENT_STATES = {
	invalid: TOURNAMENT_STATE_INVALID,
	notStarted: TOURNAMENT_STATE_NOT_STARTED,
	roundRobinOne: TOURNAMENT_STATE_ROUND_ROBIN_ONE,
	roundRobinTwo: TOURNAMENT_STATE_ROUND_ROBIN_TWO,
	completed: TOURNAMENT_STATE_COMPLETED,
};

export const MATCHES_DEFAULT_DATE_FOMRAT = 'YYYY-MM-DD';
export const MATCHES_START_DATE_QUERY_PARAM = 'start';
export const MATCHES_END_DATE_QUERY_PARAM = 'end';
export const MATCHES_TEAM_A_QUERY_PARAM = 'team_a';
export const MATCHES_TEAM_B_QUERY_PARAM = 'team_b';
export const MATCHES_TAGS_QUERY_PARAM = 'tags';
export const MATHCES_SORT_QUERY_PARAM = 'sort';
export const MATCHES_SORT_QUERY_PARAM_ASCENDING_VALUE = 'asc';
export const MATCHES_SORT_QUERY_PARAM_DESCENDING_VALUE = 'desc';

export const MATCHES_DEFAULT_TAGS = {
	recreation: {
		category: 'type',
		type: 'Recreation',
		label: 'Rec Game',
		color: 'green'
	},
	littlePickletesCupSeason1: {
		category: 'type',
		type: 'Little Pickletes Cup Season 1',
		label: 'Little Pickletes Cup',
		color: 'red'
	}
};
// Update this when we fix more colors for type tags
export const MATCHES_DEFAULT_MATCH_TAG_COLOR = 'gold';
export const MATCHES_SPARE_MATCH_TAG_COLORS = [
	MATCHES_DEFAULT_MATCH_TAG_COLOR,
	'magenta',
	'volcano',
	'orange',
	'lime',
	'cyan',
	'blue',
	'geekblue',
	'purple'
];

export const FAQ_QUESTIONS_ANSWERS = [
	{
		key: '1',
		label: 'When is the tournament?',
		children: <p>{'Friday, 20 Dec, 6PM to 10PM'}</p>,
	},
	{
		key: '2',
		label: 'Where will the tournament be held?',
		children: <p>{'ActiveSG Sport Village @ Jurong Town'}</p>,
	},
	{
		key: '3',
		label: 'How many people are participating?',
		children: <p>{'Eight participants'}</p>,
	},
	{
		key: '4',
		label: 'How many games do I have to play?',
		children: (
			<p>
				{
					'Everyone will play six games each. We will circulate the groupings and match schedule once all the participants are in, or latest by 23 November.'
				}
			</p>
		),
	},
	{
		key: '5',
		label: 'Will there be breaks in between games?',
		children: (
			<p>{'YES! There will be around 10-15 mins break between each game'}</p>
		),
	},
	{
		key: '6',
		label: 'Will there be warmup time?',
		children: <p>{'Please warm-up before your first game! We will have two courts available from 6-7pm.'}</p>,
	},
	{
		key: '7',
		label: 'What happens if it rains on that day?',
		children: (
			<p>
				{
					'Unfortunately there are no wet weather plans, and the Little Pickletes Cup will have to be rescheduled to one of the Fridays on the first two weeks of January 2025.'
				}
			</p>
		),
	},
	{
		key: '8',
		label: 'Who is my partner(s) for the tournament?',
		children: (
			<p>
				{
					'The eight participants will be split into two groups of four, i.e. ABCD and EFGH. Each participant will play 3 games of round-robin (AB v CD, AC v BD, AD v BC)'
				}
				<br />
				<br />
				{
					'After the first 3 games, there will be a group shuffle to re-balance the groups so the top two of each group will get grouped together. Each participant will then play another 3 games of round-robin.'
				}
			</p>
		),
	},
	{
		key: '9',
		label: 'How are the winner(s) determined?',
		children: (
			<p>
				{
					`Each game is played to ${TOURNAMENT_WINNING_POINT} (first to ${TOURNAMENT_WINNING_POINT}, no deuce). At the end of the six games, the participants will be sorted according to number of points won.`
				}
			</p>
		),
	},
	{
		key: '10',
		label: 'What happens if there is a tie-break?',
		children: (
			<p>
				{
					'If there is a tie-break, we will compare the point differential based on the opposing matchups against each other. E.g. if A and B are tied, will compare point differentials for AC v BD and AD v BC (and any other opposing match ups).'
				}
			</p>
		),
	},
	{
		key: '11',
		label: 'Can I track the live score / rankings?',
		children: (
			<p>
				{
					'There will be a live leaderboard - check back on the Results tab when the tournament has started!'
				}
			</p>
		),
	},
];
