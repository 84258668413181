import React, { useState, useEffect } from 'react';
import './MatchFilter.css';
import PropTypes from 'prop-types';
import { DatePicker, Select, Checkbox } from 'antd';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import {MATCHES_DEFAULT_DATE_FOMRAT,
	MATCHES_END_DATE_QUERY_PARAM, 
	MATCHES_START_DATE_QUERY_PARAM, 
	MATCHES_TEAM_A_QUERY_PARAM, 
	MATCHES_TEAM_B_QUERY_PARAM,
	MATCHES_TAGS_QUERY_PARAM,
	MATHCES_SORT_QUERY_PARAM,
	MATCHES_SORT_QUERY_PARAM_ASCENDING_VALUE,
	MATCHES_SORT_QUERY_PARAM_DESCENDING_VALUE,
	MATCHES_DEFAULT_TAGS } from '../constants';
import { getAllTags, getMatchTagLabels } from '../helpers/matches';

const { RangePicker } = DatePicker;

const START_OF_TIME = dayjs().subtract(100, 'year').format(MATCHES_DEFAULT_DATE_FOMRAT);
const END_OF_TIME = dayjs().add(100, 'year').format(MATCHES_DEFAULT_DATE_FOMRAT);

const playersSetToOptions = (playersSet) => [...playersSet]
	.sort()
	.map(p => {
		return {
			label: p,
			value: p
		};
	});

const getFilteredMatches = (allMatches, searchParams, dateFilter, teamAPlayersFilter, teamBPlayersFilter, tagsFilter) => {
	const matchesDate = (match) => dateFilter[0] <= match.date && dateFilter[1] >= match.date;
	const matchesPlayersFilter = (match) => {
		const inMatchTeamA = player => match.teams[0].players.includes(player);
		const inMatchTeamB = player => match.teams[1].players.includes(player);
		return ([...teamAPlayersFilter].every(inMatchTeamA) && [...teamBPlayersFilter].every(inMatchTeamB))
            || ([...teamAPlayersFilter].every(inMatchTeamB) && [...teamBPlayersFilter].every(inMatchTeamA));
	};
	// Checks that all the select tags (tagsFilter) are found in the set of match tags via Set difference
	const matchesTagsFilter = (match) => caseAndWhiteSpaceInsensitiveDifference(
		new Set(tagsFilter), 
		new Set(getMatchTagLabels(match))
	).size === 0;

	return allMatches
		.filter(matchesDate)
		.filter(matchesPlayersFilter)
		.filter(matchesTagsFilter)
		// Descending order of date by default
		.sort((m1, m2) => m1.date.localeCompare(m2.date) * 
			caseAndWhiteSpaceInsensitiveCompare(searchParams.get(MATHCES_SORT_QUERY_PARAM), MATCHES_SORT_QUERY_PARAM_ASCENDING_VALUE)
			? 1 : -1);
};

const validateQueryParams = (
	searchParams, 
	players,
	matchTags
) => {
	// Validate dates
	const [startDate, endDate] = getQueryParamsDateRange(searchParams);

	if (!startDate.isValid()) {
		searchParams.delete(MATCHES_START_DATE_QUERY_PARAM);
	}
	if (!endDate.isValid()) {
		searchParams.delete(MATCHES_END_DATE_QUERY_PARAM);
	}

	// Filter out start > end
	if (startDate.isValid() && endDate.isValid() && startDate.isAfter(endDate)) {
		searchParams.delete(MATCHES_START_DATE_QUERY_PARAM);
		searchParams.delete(MATCHES_END_DATE_QUERY_PARAM);
	}
	
	// Validate teams
	// But first, filter out players whose names do not exist
	// and update the proper casing and spacing in names
	const teamA = dedup(filterAndTransformCaseAndWhiteSpaceInsensitivePlayers(getQueryParamsTeamA(searchParams), players));
	const teamB = dedup(filterAndTransformCaseAndWhiteSpaceInsensitivePlayers(getQueryParamsTeamB(searchParams), players));

	// Filter out invalid players
	if (teamA.length === 0) {
		searchParams.delete(MATCHES_TEAM_A_QUERY_PARAM);
	} else {
		searchParams.set(MATCHES_TEAM_A_QUERY_PARAM, teamA.join(','));
	}
	if (teamB.length === 0) {
		searchParams.delete(MATCHES_TEAM_B_QUERY_PARAM);
	} else {
		searchParams.set(MATCHES_TEAM_B_QUERY_PARAM, teamB.join(','));
	}

	// Filter out players in both teams
	if (caseAndWhiteSpaceInsensitiveIntersection(new Set(teamA), new Set(teamB)).size !== 0) {
		searchParams.delete(MATCHES_TEAM_A_QUERY_PARAM);
		searchParams.delete(MATCHES_TEAM_B_QUERY_PARAM);
	}

	// Validate match tags
	const tags = dedup(filterAndTransformCaseAndWhiteSpaceInsensitiveTags(getQueryParamsTags(searchParams), matchTags));
	// Filter out tags if there are other tags also in the same category
	const withoutTagsInMatchingCategory = filterTagsWithOtherTagsInMatchingCategory(tags);
	
	if (withoutTagsInMatchingCategory.length === 0) {
		searchParams.delete(MATCHES_TAGS_QUERY_PARAM);
	} else {
		searchParams.set(MATCHES_TAGS_QUERY_PARAM, withoutTagsInMatchingCategory.join(','));
	}

	// Validate sort
	if (
		[MATCHES_SORT_QUERY_PARAM_ASCENDING_VALUE, MATCHES_SORT_QUERY_PARAM_DESCENDING_VALUE]
			.find(v => caseAndWhiteSpaceInsensitiveCompare(v, searchParams.get(MATHCES_SORT_QUERY_PARAM)))
	) {
		searchParams.set(MATHCES_SORT_QUERY_PARAM, searchParams.get(MATHCES_SORT_QUERY_PARAM).toLowerCase());
	} else {
		searchParams.delete(MATHCES_SORT_QUERY_PARAM);
	}
};

const updateQueryParamsWithFilters = (
	searchParams, 
	setSearchParams, 
	dateFilter, 
	teamAPlayersFilter, 
	teamBPlayersFilter,
	tagsFilter
) => {
	// Update dates
	if (dateFilter[0] === START_OF_TIME) {
		searchParams.delete(MATCHES_START_DATE_QUERY_PARAM);
	} else {
		searchParams.set(MATCHES_START_DATE_QUERY_PARAM, dateFilter[0]);
	}
	if (dateFilter[1] === END_OF_TIME) {
		searchParams.delete(MATCHES_END_DATE_QUERY_PARAM);
	} else {
		searchParams.set(MATCHES_END_DATE_QUERY_PARAM, dateFilter[1]);
	}

	// Update players
	if (teamAPlayersFilter.size === 0) {
		searchParams.delete(MATCHES_TEAM_A_QUERY_PARAM);
	} else {
		searchParams.set(MATCHES_TEAM_A_QUERY_PARAM, [...teamAPlayersFilter].join(','));
	}
	if (teamBPlayersFilter.size === 0) {
		searchParams.delete(MATCHES_TEAM_B_QUERY_PARAM);
	} else {
		searchParams.set(MATCHES_TEAM_B_QUERY_PARAM, [...teamBPlayersFilter].join(','));
	}

	// Update tags
	if (tagsFilter.size === 0) {
		searchParams.delete(MATCHES_TAGS_QUERY_PARAM);
	} else {
		searchParams.set(MATCHES_TAGS_QUERY_PARAM, [...tagsFilter].join(','));
	}

	setSearchParams(searchParams);
};

const getRangePickerValue = (searchParams) => {
	const [startDate, endDate] = getQueryParamsDateRange(searchParams);

	if (startDate.isValid() && endDate.isValid()) {
		return startDate.isAfter(endDate) ? [] : [startDate, endDate];
	} else if (startDate.isValid()) {
		return [startDate, null];
	} else if (endDate.isValid()) {
		return [null, endDate];
	}

	return [];
};

const getTeamASelectValue = (searchParams, players) => 
	(searchParams.get(MATCHES_TEAM_A_QUERY_PARAM) || '')
		.split(',')
		.filter(v => !!v)
		.filter(v => players.has(v));

const getTeamBSelectValue = (searchParams, players) => 
	(searchParams.get(MATCHES_TEAM_B_QUERY_PARAM) || '')
		.split(',')
		.filter(v => !!v)
		.filter(v => players.has(v));

const getTagsSelectValue = (searchParams, tags) => 
	(searchParams.get(MATCHES_TAGS_QUERY_PARAM) || '')
		.split(',')
		.filter(v => !!v)
		.filter(v => new Set(tags).has(v));

const getQueryParamsDateRange = (searchParams) => [
	dayjs(
		searchParams.get(MATCHES_START_DATE_QUERY_PARAM), 
		MATCHES_DEFAULT_DATE_FOMRAT
	),
	dayjs(
		searchParams.get(MATCHES_END_DATE_QUERY_PARAM), 
		MATCHES_DEFAULT_DATE_FOMRAT
	)
];

const getQueryParamsTeamA = (searchParams) => 
	(searchParams.get(MATCHES_TEAM_A_QUERY_PARAM) || '')
		.split(',').filter(v => !!v);

const getQueryParamsTeamB = (searchParams) => 
	(searchParams.get(MATCHES_TEAM_B_QUERY_PARAM) || '')
		.split(',').filter(v => !!v);

const getQueryParamsTags = (searchParams) => 
	(searchParams.get(MATCHES_TAGS_QUERY_PARAM) || '')
		.split(',').filter(v => !!v);

const filterAndTransformCaseAndWhiteSpaceInsensitivePlayers = (toTransform, players) =>
	toTransform
		.filter(t => players.size === 0 || [...players].some(p => caseAndWhiteSpaceInsensitiveCompare(p, t)))
		.map(t => players.size === 0 ? t : [...players].find(p => caseAndWhiteSpaceInsensitiveCompare(p, t)));

const filterAndTransformCaseAndWhiteSpaceInsensitiveTags = (toTransform, tags) =>
	toTransform
		.filter(t => tags.size === 0 || [...tags].some(tag => caseAndWhiteSpaceInsensitiveCompare(tag, t)))
		.map(t => tags.size === 0 ? t : [...tags].find(tag => caseAndWhiteSpaceInsensitiveCompare(tag, t)));

const caseAndWhiteSpaceInsensitiveCompare = (first, second) =>
	(first || '').toLowerCase().replace(/\s+/g, '') === (second || '').toLowerCase().replace(/\s+/g, '');

const caseAndWhiteSpaceInsensitiveIntersection = (setA, setB) => 
	new Set([...setA].map(v => v.toLowerCase().replace(/\s+/g, '')))
		.intersection(new Set([...setB].map(v => v.toLowerCase().replace(/\s+/g, ''))));

const caseAndWhiteSpaceInsensitiveDifference = (setA, setB) => 
	new Set([...setA].map(v => v.toLowerCase().replace(/\s+/g, '')))
		.difference(new Set([...setB].map(v => v.toLowerCase().replace(/\s+/g, ''))));

const dedup = (arr) => [...new Set(arr)];

// Return true iff
// Some other tag with the same category as this tag is contained in tagsFilter already
// and this current tag is not inside the tags Filter
const isMatchingCategoryTagAlreadyChecked = (tag, tagsFilter) => 
	Object.entries(MATCHES_DEFAULT_TAGS)
		.some(([, otherTag]) => 
			otherTag.label !== tag 
			&& otherTag.category === getTagCategoryForLabel(tag)
			&& tagsFilter.has(otherTag.label)
		) 
	&& !tagsFilter.has(tag);
// Filter out tags if there are other tags also in the same category
const filterTagsWithOtherTagsInMatchingCategory = (tags) => tags
	.filter((t) => 	!tags
		.some((otherTag) => 
			otherTag !== t 
			&& getTagCategoryForLabel(otherTag) === getTagCategoryForLabel(t)
		) 
	);
const getTagCategoryForLabel = (tagLabel) => 
	Object.entries(MATCHES_DEFAULT_TAGS)
		.filter(([, t]) => t.label === tagLabel)
		.map(([, t]) => t.category)
		.find(() => true);

const MatchFilter = ({ allMatches = [], setMatches = () => {} }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [initialStart, initialEnd] = getRangePickerValue(searchParams);

	const players = new Set(allMatches.flatMap(m => m.teams.flatMap(t => t.players)));
	const matchTags = getAllTags(allMatches);

	const [minDate, setMinDate] = useState(START_OF_TIME);
	const [maxDate, setMaxDate] = useState(END_OF_TIME);

	useEffect(() => {
		validateQueryParams(searchParams, players, matchTags);
		setMinDate(allMatches
			.reduce((prev, curr) => prev.date <= curr.date ? prev : curr, new Object()).date || minDate
		);
		setMaxDate(allMatches
			.reduce((prev, curr) => prev.date >= curr.date ? prev : curr, new Object()).date || maxDate
		);
		setSearchParams(searchParams);

		// Once all matches has loaded, filter out invalid players and tags 
		if (players.size !== 0) {
			setTeamAPlayersFilter(
				new Set(filterAndTransformCaseAndWhiteSpaceInsensitivePlayers([...teamAPlayersFilter],players))
			);
			setTeamBPlayersFilter(
				new Set(filterAndTransformCaseAndWhiteSpaceInsensitivePlayers([...teamBPlayersFilter],players))
			);
		} else if (caseAndWhiteSpaceInsensitiveIntersection(teamAPlayersFilter, teamBPlayersFilter).size !== 0) {
			setTeamAPlayersFilter(new Set());
			setTeamBPlayersFilter(new Set());
		}

		if (matchTags.size !== 0) {
			setTagsFilter(
				new Set(filterAndTransformCaseAndWhiteSpaceInsensitiveTags([...tagsFilter],matchTags))
			);
		}
	}, [allMatches]);

	const [dateFilter, setDateFilter] = useState([initialStart?.format(MATCHES_DEFAULT_DATE_FOMRAT) || minDate, initialEnd?.format(MATCHES_DEFAULT_DATE_FOMRAT) || maxDate]);
	const [teamAPlayersFilter, setTeamAPlayersFilter] = useState(new Set(
		dedup(filterAndTransformCaseAndWhiteSpaceInsensitivePlayers(getQueryParamsTeamA(searchParams), players))
	));
	const [teamBPlayersFilter, setTeamBPlayersFilter] = useState(new Set(
		dedup(filterAndTransformCaseAndWhiteSpaceInsensitivePlayers(getQueryParamsTeamB(searchParams), players))
	));
	const [tagsFilter, setTagsFilter] = useState(new Set(
		filterTagsWithOtherTagsInMatchingCategory(dedup(filterAndTransformCaseAndWhiteSpaceInsensitiveTags(getQueryParamsTags(searchParams), matchTags)))
	));

	useEffect(() => {
		setMatches(getFilteredMatches(allMatches, searchParams, dateFilter, teamAPlayersFilter, teamBPlayersFilter, tagsFilter));
		updateQueryParamsWithFilters(searchParams, setSearchParams, dateFilter, teamAPlayersFilter, teamBPlayersFilter, tagsFilter);
	}, [allMatches, dateFilter, teamAPlayersFilter, teamBPlayersFilter, tagsFilter]);

	const options = playersSetToOptions(
		players.difference(
			teamAPlayersFilter
				.union(teamBPlayersFilter)
				.union(new Set(getTeamASelectValue(searchParams, players)))
				.union(new Set(getTeamBSelectValue(searchParams, players)))
		)
	);

	return (
		<div className='match-filter-wrapper'>
			<div className='date-filter-wrapper'>
				<RangePicker 
					placeholder={['From Start', 'Till Now']}
					popupClassName="dateRangePicker"
					allowEmpty={[true, true]}
					value={getRangePickerValue(searchParams)}
					onChange={(_dates, dateStrings) => {
						// A second [''] onChange is triggered if only one date selected on an empty RangePicker
						if (dateStrings.length === 2) {
							setDateFilter([dateStrings[0] || START_OF_TIME, dateStrings[1] || END_OF_TIME]);
						}
					}}
					disabled={allMatches.length === 0}
				/>
			</div>
			<div className='player-filter-wrapper'>
				<Select
					mode='multiple'
					style={{
						width: '50%' 
					}}
					allowClear
					defaultValue={[]}
					maxCount={2}
					value={allMatches.length === 0 ? [] : getTeamASelectValue(searchParams, players)}
					onChange={(values) => setTeamAPlayersFilter(new Set(values))}
					options={options}
					disabled={allMatches.length === 0}
				/>
				<p style={{
					color: 'white',
					fontWeight: 600
				}}>{'VS'}</p>
				<Select
					mode='multiple'
					style={{
						width: '50%',
					}}
					allowClear
					defaultValue={[]}
					maxCount={2}
					value={allMatches.length === 0 ? [] : getTeamBSelectValue(searchParams, players)}
					onChange={(values) => setTeamBPlayersFilter(new Set(values))}
					options={options}
					disabled={allMatches.length === 0}
				/>
			</div>
			<div className='tag-filter-wrapper'>
				{
					(
						<Checkbox.Group 
							options={[...matchTags]
								.sort()
								.map(tag => {
									return {
										label: tag,
										value: tag,
										disabled: isMatchingCategoryTagAlreadyChecked(tag, tagsFilter)
									};
								})} 
							value={matchTags.length === 0 ? [] : getTagsSelectValue(searchParams, matchTags)}
							onChange={(values) => setTagsFilter(new Set(values))}
						/>
					)
				}
			</div>
		</div>
	);
};

MatchFilter.propTypes = {
	allMatches: PropTypes.array,
	setMatches: PropTypes.func
};

export default React.memo(MatchFilter);
