import React from 'react';
import './Schedule.css';
import PropTypes from 'prop-types';
import { List } from 'antd';
import { Link } from 'react-router-dom';
import { SEASON_1_DATE } from '../constants';
import {getFlattenedScheduleDetails } from '../helpers/tournament';

const Schedule = ({ scheduleAndResults }) => (
	<List
		className='schedule-list'
		bordered
		dataSource={getFlattenedScheduleDetails(scheduleAndResults)}
		header={<p className='schedule-date'>{SEASON_1_DATE.toLocaleDateString('en-US', {
			weekday: 'long', month: 'short', day: 'numeric' 
		})}</p>}
		renderItem={(item) =>
			<List.Item>
				<p className='schedule-court-time '>
					<span className='schedule-time'>
						{item.time} 
					</span>
					<span className='schedule-court'>
						<p>{item.group}</p>
						<p>Court {item.court || '- TBD'}</p>
					</span>
				</p>
				<div className='schedule-match-info'>
					<div className='team-wrapper left'>
						<p className='team-players left'>{`${item.teamAParticipantOne} / ${item.teamAParticipantTwo}`}</p>
						<p className ='score'>{item.teamAPoints || 0}</p>
					</div>
					<div className='vs-wrapper'>
						<p>vs</p>
					</div>
					<div className='team-wrapper right'>
						<p className='score'>{item.teamBPoints || 0}</p>
						<p className='team-players right'>{`${item.teamBParticipantOne} / ${item.teamBParticipantTwo}`}</p>
					</div>
				</div>
				<Link to={`/matches?tags=Little+Pickletes+Cup&team_a=${item.teamAParticipantOne},${item.teamAParticipantTwo}&team_b=${item.teamBParticipantOne},${item.teamBParticipantTwo}&sort=desc`}>
					<p className='match-link'>View Match</p>
				</Link>
			</List.Item>
		}
	/>
);

Schedule.propTypes = {
	scheduleAndResults: PropTypes.array
};

export default Schedule;