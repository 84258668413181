import React from 'react';
import './Home.css';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import poster from '../assets/Little-Pickletes-Cup-Badge.png';
import SnowingBackground from '../components/SnowingBackground';
import CustomCountdown from '../components/CustomCountdown';
import Players from '../components/Players';
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';
import { Image, Typography, Space, Collapse, Button } from 'antd';
import { SEASON_1_START_DATE_TIME, SEASON_1_PROMO_VIDEO, FAQ_QUESTIONS_ANSWERS, TOURNAMENT_STATES } from '../constants';
import { getTournamentState } from '../helpers/tournament';
import ResponsiveEmbed from 'react-responsive-embed';

const Home = ({ tournamentDetails = new Object(), allMatches = [] }) => {
	const { players } = tournamentDetails;

	const renderCountdownOrScheduleRedirect = (tournamentDetails) => {
		const isPastStartTime = new Date().getTime() > SEASON_1_START_DATE_TIME;
		let isTournamentCompleted = getTournamentState(tournamentDetails) === TOURNAMENT_STATES.completed;

		// Show countdown if tournament has not started according to start date/time
		if (!isPastStartTime) {
			return (<CustomCountdown targetDate={SEASON_1_START_DATE_TIME} />);
		}

		const isTournamentDetailsFetched = (tournamentDetails.players || []).length > 0
			&& (tournamentDetails.standings || []).length > 0
			&& (tournamentDetails.scheduleAndResults || []).length > 0;
	
		// Else render a button that redirects to the schedule page
		return (
			<Link to='/results'>
				<Button className='hero-button button' size='large' >
					{isTournamentCompleted || !isTournamentDetailsFetched ? 'View Results!' : 'Follow Live!'}
				</Button>
			</Link>
		);
	};

	return (
		<>
			<section className="body-wrapper home-body-wrapper">
				<NavigationBar activeKey='home' />
				<div id="home-container" className="container">
					<SnowingBackground />
					<div className="hero-section section">
						<Space
							direction="vertical"
							size="middle"
							style={{
								display: 'flex',
							}}
						>
							<Image src={poster} placeholder={false} preview={false} />
							{renderCountdownOrScheduleRedirect(tournamentDetails)}
						</Space>
					</div>
					<div className="promo-section section">
						<ResponsiveEmbed src={SEASON_1_PROMO_VIDEO} allowFullScreen ratio='16:9' />
					</div>
					<div className="players-section section">
						<Players players={players} allMatches={allMatches} />
					</div>
					<div className="faq-section section">
						<Typography.Title
							editable={false}
							level={2}
							style={{
								margin: 0,
								marginBottom: 32,
							}}
						>
								FAQs
						</Typography.Title>
						<Space
							direction="vertical"
							size="large"
							style={{
								display: 'flex',
							}}
						>
							<Collapse
								items={FAQ_QUESTIONS_ANSWERS}
								defaultActiveKey={[]}
								bordered={false}
								size="large"
							/>
						</Space>
					</div>
					<Footer />
				</div>
			</section>
		</>
	);
};

Home.propTypes = {
	tournamentDetails: PropTypes.object,
	allMatches: PropTypes.array
};

export default Home;
