import React, { useState } from 'react';
import './Matches.css'; 
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import MatchFilter from '../components/MatchFilter';
import Match from '../components/Match';
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';
import { Empty } from 'antd';
import { MATCHES_DEFAULT_TAGS, MATCHES_SPARE_MATCH_TAG_COLORS } from '../constants';
import { getAllCustomTags } from '../helpers/matches';


const getColorsForTag = (matches) => {
	const typeTags = Object.entries(MATCHES_DEFAULT_TAGS)
		.map(([, tag]) => {
			return {
				[tag.label]: tag.color
			};
		}).reduce((prev, curr) => {
			return {
				...prev,
				...curr
			};
		}, new Object());

	// For each match, generate a randomized order of tag colors, 
	// and repeat this order for as many tags as there are for this match
	const randomizedTagColorsOrder = MATCHES_SPARE_MATCH_TAG_COLORS.sort(() => 0.5 - Math.random());
	const allCustomTags = [...getAllCustomTags(matches)];
	const randomizedTags = [...Array(Math.max(Math.ceil(allCustomTags.length / randomizedTagColorsOrder.length), 1)).keys()]
		.map(() => randomizedTagColorsOrder)
		.reduce((arr1, arr2) => arr1.concat(arr2), []);

	return {
		// Only display match filter tags when all matches have loaded, kinda hacky
		...(matches.length === 0 ? new Object() : typeTags),
		...allCustomTags
			.map((tag, idx) => {
				return {
					[tag]: randomizedTags[idx]
				};
			}).reduce((prev, curr) => {
				return {
					...prev,
					...curr
				};
			}, new Object())
	};
};

const Matches = ({ allMatches = [] }) => {
	const [filteredMatches, setFilteredMatches] = useState(allMatches);
	const colorsForTag = getColorsForTag(allMatches);

	return (
		<section className ='body-wrapper matches-body-wrapper'>
			<NavigationBar activeKey='matches'/>
			<div id="matches-container" className='container'>
				<MatchFilter allMatches={allMatches} setMatches={setFilteredMatches} />
				<Divider style={{
					background:'rgba(255,255,255,0.2)', minWidth: '90%', width: '90%'
				}}/>
				{
					filteredMatches.length === 0
						? (
							<Empty 
								description={(
									<span style={{
										color: 'white', fontWeight: 'bold' 
									}}>
									No matches found!
									</span>)} 		
							/>
						) : filteredMatches.map((m, idx) => (<Match key={idx} match={m} colorsForTag={colorsForTag} />))
				}
			</div>
			<Footer />
		</section>
	);
};

Matches.propTypes = {
	allMatches: PropTypes.array
};

export default React.memo(Matches);
