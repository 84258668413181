import React from 'react';
import './Gallery.css';
import PropTypes from 'prop-types';
import { Tabs, Image } from 'antd';
import Masonry from 'react-responsive-masonry';
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';

const getGalleryTabs = (allImages) => [
	{
		key: 'all',
		label: 'All',
		children: getImagesTab((allImages.main || []).concat(allImages.littlePickletesCupSeason1 || []))
	},
	{
		key: 'littlePickletesCup',
		label: 'Little Pickletes Cup',
		children: getImagesTab(allImages.littlePickletesCupSeason1 || [])
	},
	{
		key: 'others',
		label: 'Others',
		children: getImagesTab(allImages.main || [])
	}
];

const getImagesTab = (images) => (
	<Masonry
		columnsCount={3}
		gutter={16}
		containerTag="masonry-wrapper"
		itemTag="masonry-item"
	>
		{
			// Randomize the order of the images shown 
			// So all images get displayed at the top, eventually..
			images.sort(() => 0.5 - Math.random())
				.map((img, idx) => (
					<Image key={idx} src={img} />
				))
		}
	</Masonry>
);

const Gallery = ({ images }) => (
	<section className="body-wrapper gallery-body-wrapper">
		<NavigationBar activeKey='gallery' />
		<div id="gallery-container" className='container'>
			<Tabs defaultActiveKey="all" items={getGalleryTabs(images)} />
			<Footer />
		</div>
	</section>
);

Gallery.propTypes = {
	images: PropTypes.object
};

export default React.memo(Gallery);
