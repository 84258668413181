import {LAMBDA_FUNCTION_URL, 
	SAMPLE_GALLERY_MAIN_IMAGES,
	SAMPLE_GALLERY_LITTLE_PICKLETES_CUP_SEASON_1_IMAGES} from './constants';
import { WEBSITE_ENV } from './env';

const SAMPLE_IMAGES = {
	main: SAMPLE_GALLERY_MAIN_IMAGES,
	littlePickletesCupSeason1: SAMPLE_GALLERY_LITTLE_PICKLETES_CUP_SEASON_1_IMAGES,
};

export const getGeneralDetails = async (season) =>
	fetch(`${LAMBDA_FUNCTION_URL}/?season=${season}&type=general&env=${WEBSITE_ENV}`)
		.then((response) => response.json())
		.catch(() => {
			return {
				players: [],
			};
		});

export const getTournamentDetails = async (season) =>
	fetch(`${LAMBDA_FUNCTION_URL}/?season=${season}&type=results&env=${WEBSITE_ENV}`)
		.then((response) => response.json())
		.catch(() => {
			return {
				standings: [],
				scheduleAndResults: [],
			};
		});

// To do
export const getPickletesGallery = async () => Promise.resolve(SAMPLE_IMAGES);