import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import Main from './components/Main';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from 'history';
import ScrollToTop from './components/ScrollToTop';

// https://stackoverflow.com/questions/66326106/react-router-locations-on-aws-s3-bucket-do-not-work
const replaceHashPath = () => {
	const history = createBrowserHistory();
	const hash = history.location.hash;
	if (hash) {
		const path = hash.replace(/^#/, '');
		if (path) {
			history.replace(path);
		}
	}
};
replaceHashPath();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<BrowserRouter>
		<ScrollToTop/>
		<Main />
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
