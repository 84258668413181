import { TOURNAMENT_WINNING_POINT, TOURNAMENT_STATES, RANDOM_PARTICIPANT_IMAGES } from '../constants';

/*
Format for `tournamentDetails`

{
  "standings": [
    {
      "position": 1, // Values 1-8, 1 being the first placed participant
      "name": "Shirin",
      "score": 126
    },
    ...
  ],
  "scheduleAndResults": [
    {
      "roundRobin": 1, // Values 1 or 2, describing the round robin round #, where #1 is the first round of 3 matches and #2 is the next round of 3 matches
      "results": {
        "groupA": {
      	  "time": "6:30PM",
          "court": 1, // values 1-4, aka JV courts
          "teamA": {
            "participants": [
              "Shirin",
              "Sandra"
            ],
            "points": 21 // for example
          },
          "teamB": {
            "participants": [
              "Helena",
              "Jen Chan"
            ],
            "points": 19
          }
        },
        "groupB": {
      	  "time": "6:30PM",
          "court": 2,
          "teamA": {
            "participants": [
              "Lay Hoon",
              "Kirsten"
            ],
            "points": 21 // for example
          },
          "teamB": {
            "participants": [
              "Angeline",
              "Player 8"
            ],
            "points": 1
          }
        }
      }
    },
    ...
  ]
}
*/

export const getRandomParticipantAvatar = (key) => 
	RANDOM_PARTICIPANT_IMAGES[(key - 1) % (RANDOM_PARTICIPANT_IMAGES.length - 1)];

export const getTournamentState = (tournamentDetails) => {
	// Not started if no participant has any points
	if ((tournamentDetails.standings || []).every(s => !s.score)) {
		return TOURNAMENT_STATES.notStarted;
	}

	// Completed if all round robin 2 matches have a team with at least TOURNAMENT_WINNING_POINT points
	if ((tournamentDetails.scheduleAndResults || []).filter(s => s.roundRobin === 2)
		.every(s => 
			((s.results.groupA.teamA.points || 0) >= TOURNAMENT_WINNING_POINT || (s.results.groupA.teamB.points  || 0) >= TOURNAMENT_WINNING_POINT)
            && ((s.results.groupB.teamA.points || 0) >= TOURNAMENT_WINNING_POINT || (s.results.groupB.teamB.points  || 0) >= TOURNAMENT_WINNING_POINT)
		)) {
		return TOURNAMENT_STATES.completed;
	}

	// Round robin two if all round robin 1 matches have a team with at least TOURNAMENT_WINNING_POINT points
	if ((tournamentDetails.scheduleAndResults || []).filter(s => s.roundRobin === 1)
		.every(s => 
			((s.results.groupA.teamA.points || 0) >= TOURNAMENT_WINNING_POINT || (s.results.groupA.teamB.points  || 0) >= TOURNAMENT_WINNING_POINT)
            && ((s.results.groupB.teamA.points || 0) >= TOURNAMENT_WINNING_POINT || (s.results.groupB.teamB.points  || 0) >= TOURNAMENT_WINNING_POINT)
		)) {
		return TOURNAMENT_STATES.roundRobinTwo;
	}

	// Round robin one if some round robin 1 matches have no teams with at least TOURNAMENT_WINNING_POINT points
	if ((tournamentDetails.scheduleAndResults || []).filter(s => s.roundRobin === 1)
		.some(s => 
			((s.results.groupA.teamA.points || 0) < TOURNAMENT_WINNING_POINT && (s.results.groupA.teamB.points  || 0) < TOURNAMENT_WINNING_POINT)
            || ((s.results.groupB.teamA.points || 0) < TOURNAMENT_WINNING_POINT && (s.results.groupB.teamB.points  || 0) < TOURNAMENT_WINNING_POINT)
		)) {
		return TOURNAMENT_STATES.roundRobinOne;
	}

	return TOURNAMENT_STATES.invalid;
};

const filterRoundRobinOne = (scheduleAndResults) => scheduleAndResults.roundRobin === 1;
const filterRoundRobinTwo = (scheduleAndResults) => scheduleAndResults.roundRobin === 2;
const getGroupAParticipants = (scheduleAndResults) => scheduleAndResults.results.groupA.teamA.participants
	.concat(scheduleAndResults.results.groupA.teamB.participants);
const getGroupBParticipants = (scheduleAndResults) => scheduleAndResults.results.groupB.teamA.participants
	.concat(scheduleAndResults.results.groupB.teamB.participants);

const getSortedParticipants = (tournamentDetails, filterRoundRobin, getParticipants) => 
	[...new Set(
		tournamentDetails.scheduleAndResults 
			.filter(filterRoundRobin)
			.flatMap(getParticipants)
	)].sort();

export const getRoundRobinOneGroupAParticipants = (tournamentDetails) => 
	getSortedParticipants(tournamentDetails, filterRoundRobinOne, getGroupAParticipants);

export const getRoundRobinOneGroupBParticipants = (tournamentDetails) => 
	getSortedParticipants(tournamentDetails, filterRoundRobinOne, getGroupBParticipants);

export const getRoundRobinTwoGroupAParticipants = (tournamentDetails) => 
	getSortedParticipants(tournamentDetails, filterRoundRobinTwo, getGroupAParticipants);

export const getRoundRobinTwoGroupBParticipants = (tournamentDetails) => 
	getSortedParticipants(tournamentDetails, filterRoundRobinTwo, getGroupBParticipants);

export const getFlattenedScheduleDetails = (scheduleAndResults) => {
	return scheduleAndResults.flatMap(s => {
		return [
			{
				group: s.roundRobin === 2 ? 'Top Four' : 'Group A',
				time: s.results.groupA.time,
				court: s.results.groupA.court,
				teamAParticipantOne: s.results.groupA.teamA.participants[0],
				teamAParticipantTwo: s.results.groupA.teamA.participants[1],
				teamAPoints: s.results.groupA.teamA.points,
				teamBParticipantOne: s.results.groupA.teamB.participants[0],
				teamBParticipantTwo: s.results.groupA.teamB.participants[1],
				teamBPoints: s.results.groupA.teamB.points
			},
			{
				group: s.roundRobin === 2 ? 'Bottom Four' : 'Group B',
				time: s.results.groupB.time,
				court: s.results.groupB.court,
				teamAParticipantOne: s.results.groupB.teamA.participants[0],
				teamAParticipantTwo: s.results.groupB.teamA.participants[1],
				teamAPoints: s.results.groupB.teamA.points,
				teamBParticipantOne: s.results.groupB.teamB.participants[0],
				teamBParticipantTwo: s.results.groupB.teamB.participants[1],
				teamBPoints: s.results.groupB.teamB.points
			}
		];
	}).sort((s1, s2) => s1.time.localeCompare(s2.time));
};