import React from 'react';
import './Footer.css';
import { Typography } from 'antd';

const { Text } = Typography;

const Footer = () => (
	<div className="copyright-wrapper">
		<Text id="copyright">© Bobos.Co 2024 | pickletes.sg@gmail.com</Text>
	</div>
);

Footer.propTypes = {
};

export default React.memo(Footer);
